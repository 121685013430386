
.animate-spin-slow {
    animation: spin  5s linear infinite ; /* Adjust the duration (3s) to your desired speed */
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.section-bg {
    background-image: url("./coverCarbon.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}





